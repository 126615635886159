var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{directives:[{name:"sortable-table",rawName:"v-sortable-table",value:(_vm.dragHandle),expression:"dragHandle"}],attrs:{"custom-sort":_vm.customSort,"mobile-breakpoint":0,"loading":_vm.loading,"headers":_vm.visibleHeaders,"items":_vm.visibleItems,"item-key":("item." + (_vm.schema.key)),"items-per-page":_vm.state.itemsPerPage,"page":_vm.state.page,"sort-by":_vm.state.sortBy,"sort-desc":_vm.state.sortDesc,"hide-default-footer":!_vm.items || _vm.items.length < 10},on:{"drag-sorted":function($event){return _vm.dragSorted(Object.assign({}, $event, {items: _vm.items}))},"update:itemsPerPage":function($event){return _vm.$set(_vm.state, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.state, "itemsPerPage", $event)},"update:page":function($event){return _vm.$set(_vm.state, "page", $event)},"update:sortBy":function($event){return _vm.$set(_vm.state, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.state, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.state, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.state, "sortDesc", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_vm._t("top")]},proxy:true},{key:"header.internal.action-edit",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("view_column")])],1)]}}])},[_c('v-list',_vm._l((_vm.headerGroups),function(group){return _c('v-list-item',{key:group.groupId,on:{"click":function($event){return group.toggle()}}},[_c('v-list-item-content',[_vm._v(_vm._s(group.label))]),_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(group.visible ? 'far fa-check-square' : 'far fa-square'))])],1)],1)}),1)],1)]},proxy:true},{key:"header.internal.suites",fn:function(){return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":""}},on),[_vm._v(" "+_vm._s((_vm.state.suiteId && _vm.suitesConfig.getSuiteLabel(_vm.state.suiteId)) || _vm.translate('Suites', 'suites'))+" ")])]}}])},[_c('v-list',_vm._l((_vm.suites),function(suite,suiteId){return _c('v-list-item',{key:suiteId,on:{"click":function($event){return _vm.toggleSuite(suiteId)}}},[(_vm.state.suiteId)?_c('v-list-item-action',[(suiteId === _vm.state.suiteId)?_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-check")]):_vm._e()],1):_vm._e(),_vm._v(" "+_vm._s(_vm.suitesConfig.getSuiteLabel(suiteId))+" ")],1)}),1)],1)]},proxy:true},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',{staticClass:"mx-0 px-0"},[_c('td',{staticClass:"mx-0 px-0",attrs:{"colspan":headers.length}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.translate('Search'),"single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])]}},{key:"body",fn:function(ref){
var headers = ref.headers;
var visibleItems = ref.items;
return [_c('tbody',[(!_vm.loading && _vm.searchable)?_c('tr',{staticClass:"mx-0 px-0"},[_c('td',{staticClass:"mx-0 px-0",attrs:{"colspan":headers.length}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.translate('Search'),"single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]):_vm._e(),_vm._l((visibleItems),function(item){return _c('tr',{key:item.key},_vm._l((headers),function(h,i){return _c('td',{key:i,class:h.cls || 'text-left'},[_c(h.component,{tag:"component",attrs:{"header":h,"item":item,"items":_vm.items,"schema":_vm.schema,"selected":_vm.selected}})],1)}),0)})],2)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }