<template>
  <div>
    <v-data-table
      :custom-sort="customSort"
      v-sortable-table="dragHandle"
      v-on:drag-sorted="dragSorted({...$event, items: items})"
      :mobile-breakpoint="0"
      :loading="loading"
      :headers="visibleHeaders"
      :items="visibleItems"
      :item-key="`item.${schema.key}`"
      :items-per-page.sync="state.itemsPerPage"
      :page.sync="state.page"
      :sort-by.sync="state.sortBy"
      :sort-desc.sync="state.sortDesc"
      :hide-default-footer="!items || items.length < 10"
    >
    <template v-slot:top>
      <slot name="top"></slot>
    </template>
    <template v-slot:header.internal.action-edit>
      <v-menu offset-y>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" icon><v-icon>view_column</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item v-for="group in headerGroups" @click="group.toggle()" :key="group.groupId">
            <v-list-item-content>{{group.label}}</v-list-item-content>
            <v-list-item-action>
              <v-icon>{{group.visible ? 'far fa-check-square' : 'far fa-square'}}</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:header.internal.suites>
      <v-menu offset-y>
        <template v-slot:activator="{on}">
          <v-btn v-on="on" small text>
            {{(state.suiteId && suitesConfig.getSuiteLabel(state.suiteId)) || translate('Suites', 'suites')}}
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(suite, suiteId) in suites" @click="toggleSuite(suiteId)" :key="suiteId">
            <v-list-item-action v-if="state.suiteId">
              <v-icon v-if="suiteId === state.suiteId" small>fa-check</v-icon>
            </v-list-item-action>
             {{suitesConfig.getSuiteLabel(suiteId)}}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <!--
    <template v-slot:footer.page-text="{pageStart, pageStop}"><span>Page text</span></template>
    -->
    <template v-slot:body.prepend="{headers}" >
      <tr class="mx-0 px-0">
        <td :colspan="headers.length" class="mx-0 px-0">
          <v-text-field
            v-model="search"
            append-icon="search"
            :label="translate('Search')"
            single-line
            hide-details
            outlined/>
        </td>
      </tr>
    </template>
    <template v-slot:body="{headers, items: visibleItems}">
      <tbody>
        <tr class="mx-0 px-0" v-if="!loading && searchable">
          <td :colspan="headers.length" class="mx-0 px-0">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="translate('Search')"
              single-line
              hide-details
              outlined
              />
          </td>
        </tr>
        <tr v-for="item in visibleItems" :key="item.key">
          <td v-for="(h, i) in headers" :class="h.cls || 'text-left'" :key="i">
            <component
              :is="h.component"
              :header="h"
              :item="item"
              :items="items"
              :schema="schema"
              :selected="selected"
              />
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SortableTable from '@/directives/sortable-table'
import CollectionTableBooleanCell  from './collection-table-boolean-cell'
import CollectionTableCompletenessCell from './collection-table-completeness-cell'
import CollectionTableDataCell from './collection-table-data-cell'
import CollectionTableItemSlotCell from './collection-table-item-slot-cell'
import CollectionTableItemTemplateCell from './collection-table-item-template-cell'
import CollectionTableLinkCell from './collection-table-link-cell'
import CollectionTableSelectCell from './collection-table-select-cell'
import CollectionTableItemSuitesCell from './collection-table-item-suites-cell'
import CollectionTableSuiteSummaryCell from './collection-table-item-suite-summary-cell'
// import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'

const FlexSearch = require('flexsearch')

const makeAccessor = path => {
  const parts = path.split('.')
  return item => parts.reduce((obj, segment) => (obj === null) || (obj === undefined) ? undefined : obj[segment], item)
}

export default {
  props: [
    'schema', 'items', 'channels', 'relations', 'reverse-relations', 'suites',
    'loading', 'stateCache', 'selected',
    'sortable', 'searchable', 'draggable',
    'multi-select',
    'enable-channels', 'enable-relations', 'enable-suite-summary',
    'additional-headers',
    'suiteId', 'show-select-suite-header', 'show-suite-attribute-headers',
    'show-suite-summary'
  ],
  data: () => ({
    headerMutations: 1,
    search: '',
    filter: '',
    state: {
      suiteId: '',
      itemsPerPage: 10,
      page: 1,
      search: '',
      sortBy: [],
      sortDesc: [],
    }
  }),
  directives: {
    SortableTable
  },
  mounted () {
    this.restoreState()
  },
  watch: {
    state: {
      deep: true,
      handler: function () {
        this.saveState()
      }
    },
    items (v) {
      v && v.length && this.restoreState()
    },
    search (v) {
      this.applySearch(v)
    },
    suiteId: {
      immediate: true,
      handler (v) { this.state.suiteId = v }
    }
  },
  computed: {
    ...mapGetters(['collectionsConfig', 'channelsConfig', 'suitesConfig', 'translate', 'activeMarket', 'currentMarketId']),
    dragHandle () {
      return this.draggable && 'tr'
    },
    visibleItems () {
      let suite = this.state.suiteId
      let items = uniqBy(this.selectedItems.concat(this.filteredItems), 'key')
      if (suite) {
        return items.filter(item => item.suites[suite])
      }
      return items || []
    },
    searchFunction () { return this.buildSearchFunction(this.items) },
    filteredItems () {
      if ((this.filter || '').trim().length === 0) {
        return this.items
      }
      return this.searchFunction (this.filter)
    },
    selectedItems () {
      if (!this.selected) {
        return []
      }
      if (Object.keys(this.selected).length === 0) {
        return []
      }
      return this.items.filter(item => this.selected[item.key] === item)
    },
    headers () {
      // console.log('calculating headers')
      // Get list of ({attribute, label}) for data display
      return [
        this.getSelectHeaders(),
        this.getActionEditHeaders(),
        this.getTemplateHeaders(),
        this.getAdditionalHeaders(),
        this.getCompletenessHeaders(),
        this.getSuiteHeaders(),
        this.getSuiteHeadersForSelectedSuite(),
        this.getAttributeHeaders(),
        // this.getSuiteSummaryHeaders(),
        this.getGraphHeaders(),
        this.getActionDeleteHeaders()
      ]
        .reduce((concatenated, list) => concatenated.concat(list || []), [])
        .filter(header => header)
    },
    visibleHeaders () {
      return this.headerMutations && this.headers.filter(h => h.group ? h.group.visible : true )
    },
    headerGroups () {
      return uniqBy(
        this.headers.map(({group}) => group).filter(group => group),
        'groupId')
    }
  },
  methods: {
    applySearch: debounce(function (v) {
      this.state.search = v
      this.filter = v
    }, 500),
    dragSorted ({newIndex, oldIndex, items} = event) {
      items.splice(newIndex, 0, items.splice(oldIndex, 1)[0])
      // forward event to parent for info
      this.$emit('drag-sorted', event)
    },
    toggleSuite (suiteId) {
      this.state.suiteId = (this.state.suiteId === suiteId) ? '' : suiteId
    },

    getSuiteSummaryHeaders () {
      let hasSuites = this.visibleItems.some(({suites}) => suites)
      if (!hasSuites) { return }
      // enable-suite-summary
      let group = this.createHeaderGroup('suite-summary', this.translate('Suite summary'), !!this.showSuiteSummary)
      return [{
        group,
        text: this.translate('Suites'),
        sortable: false,
        component: CollectionTableSuiteSummaryCell
      }]
    },

    getAdditionalHeaders() {
      return this.additionalHeaders
    },

    getSelectHeaders () {
      return this.selected && [{
        width: '1%',
        text: '',
        value: '$internal.select',
        sortable: false,
        component: CollectionTableSelectCell
      }]
    },

    getActionEditHeaders () {
      let slot = this.$scopedSlots['edit-item']
      if (slot) {
        return [{
          slot,
          width: '1%',
          text: '',
          value: 'internal.action-edit',
          sortable: false,
          component: CollectionTableItemSlotCell
        }]
      }
    },
    getActionDeleteHeaders () {
      let slot = this.$scopedSlots['delete-item']
      if (slot) {
        return [{
          slot,
          cls: 'text-right',
          // width: '1%',
          text: '',
          value: '$internal.action-delete',
          sortable: false,
          component: CollectionTableItemSlotCell,
        }]
      }
    },
    getTemplateHeaders () {
      let template = this.collectionsConfig.getCollectionTableItemTemplate(this.schema)
      if (template) {
        return [{
          text: '',
          value: '$internal.item-template',
          sortable: false,
          component: CollectionTableItemTemplateCell,
          template
        }]
      }
    },
    getGraphHeaders () {
      let group = this.createHeaderGroup('graph', this.translate('Relations'))
      return [
        this.relations && Object.keys(this.relations).length && {
          group,
          text: '\u27E5',
          value: 'relations.sourceCount',
          sortable: this.sortable,
          component: CollectionTableDataCell
        },
        this.reverseRelations && Object.keys(this.reverseRelations).length && {
          group,
          text: '\u27E4',
          value: 'relations.targetCount',
          sortable: this.sortable,
          component: CollectionTableDataCell
        }
      ]
    },
    getSuiteHeaders () {
      // Enable/Disable user selection of suite filtering
      if (!this.showSelectSuiteHeader) {
        return
      }
      let suites = Object.values(this.suites || [])
      if (suites.length) {
        return [{
          text: '',
          value: 'internal.suites',
          sortable: false,
          component: CollectionTableItemSuitesCell,
          class: 'secondary'
        }]
      }
    },
    getSuiteHeadersForSelectedSuite () {
      if (!this.showSuiteAttributeHeaders) {
        return
      }
      let suiteSchema = this.suites && this.suites[this.state.suiteId]
      if (suiteSchema) {
        const {suiteId, key} = suiteSchema
        let group = this.createHeaderGroup('selected-suite', this.translate('Suite'))

        const getAttributeValuePath = ({name, multimarket}) => multimarket ? `suites.${suiteId}.${name}.${this.currentMarketId}` : `suites.${suiteId}.${name}`
        const getLabel = ({multimarket}, label) => multimarket ? `${label} (${this.activeMarket.description})` : label
        return this.suitesConfig.mapSuitetableAttributes(suiteSchema)
          .filter(({attribute}) => attribute.name !== key)
          .map(({attribute, label}) => ({
            group,
            text: getLabel(attribute, label),
            // value: `suites.${suiteId}.${attribute.name}`,
            value: getAttributeValuePath(attribute),
            sortable: this.sortable,
            component: this.getAttributeCellComponent(attribute),
            class: 'info',
            searchable: true
          }))
      }
    },
    getAttributeHeaders () {
      const getAttributeValuePath = ({name, multimarket}) => multimarket ? `item.${name}.${this.currentMarketId}` : `item.${name}`
      const getLabel = ({multimarket}, label) => multimarket ? `${label} (${this.activeMarket.description})` : label
      let group = this.createHeaderGroup('attribute', this.translate('Attributes'))
      return this.collectionsConfig.mapCollectionTableAttributes(this.schema)
        .map(({attribute, label}) => ({
          group,
          text: getLabel(attribute, label),
          value: getAttributeValuePath(attribute),
          sortable: this.sortable,
          component: this.getAttributeCellComponent(attribute),
          searchable: true
        }))
    },
    getCompletenessHeaders () {
      let group = this.createHeaderGroup('completeness', this.translate('Channels'))
      return this.channelsConfig.mapCollectionTableChannels(this.channels)
        .map(({channelId, label}) => ({
          group,
          text: label,
          value: `completeness.${channelId}`,
          sortable: this.sortable,
          component: CollectionTableCompletenessCell
        }))
    },
    getAttributeCellComponent ({type}) {
      if (type === 'image') {
        return CollectionTableLinkCell
      }
      if (type === 'url') {
        return CollectionTableLinkCell
      }
      if (type === 'boolean') {
        return CollectionTableBooleanCell
      }
      return CollectionTableDataCell
    },
    restoreState () {
      // The idea here is to restore table state regarding pagination and sorting
      // Its important that each application of a property is carried out in a separate tick
      // to properly emulate a user click sequence since setting all at once will reset some properties
      const p = this.stateCache && this.stateCache.get()
      const applyNext = (q, final) => {
        if (q.length === 0) {
          return final()
        }
        let key = q.shift()
        key && this.$nextTick(() => {
          this.$set(this.state, key, p[key])
          applyNext(q, final)
        })
      }

      p && applyNext(['itemsPerPage', 'page', 'sortBy', 'sortDesc', 'search', 'suiteId'], () => {
        this.search = this.state.search || ''
      })
      /*
      // The idea here is to restore table state regarding pagination and sorting
      // Its important that each application of a property is carried out in a separate tick
      // to properly emulate a user click sequence since setting all at once will reset some properties
      const p = this.stateCache && this.stateCache.get()
      const applyNext = (q) => {
        let key = q.shift()
        key && this.$nextTick(() => {
          this.$set(this.state, key, p[key])
          applyNext(q)
        })
      }

      p && applyNext(['itemsPerPage', 'page', 'sortBy', 'sortDesc', 'search'])
      */
    },
    saveState () {
      this.stateCache && this.stateCache.set(this.state)
    },
    createHeaderGroup (groupId, label, visible = true) {
      let self = this
      return {
        groupId, label, visible,
        toggle () {
          this.visible = !this.visible
          ++self.headerMutations
        }
      }
    },
    buildSearchFunction (items) {
      const makeIndexValue = v => typeof v === 'string' ? v : typeof v === 'number' ? v.toString() : typeof v === 'boolean' ? (v ? 'on' : 'off') : ''

      let index = new FlexSearch()

      // keys in flexsearch are preferably integers
      // we give each item a natural index via a nametable
      /*
      let indexByKey = items.reduce((nt, {key}, index) => {
        nt[key] = index
        return nt
      }, {})
      let keyByIndex = items.map(({key}) => key)
      */

      let accessors = this.headers
        .filter(({searchable}) => searchable)
        .map(({value}) => makeAccessor(value))

      items.forEach((item, i) => index.add(i, accessors.map(a => makeIndexValue(a(item))).join(' ')))


      return search => index.search(search).map(i => items[i])
    },

    /********************************************************
      *
      * Custom sorting of v-data-table
      *
      * - selected items will always appear top in ANY sorting
      * - better performance due to better caching in makeAccessor
      *   leading to fewer 'x.y.z'.split() for value lookups
      *
    ********************************************************/
    customSort (items, values, orders /*, locale, customSorters*/) {
      if (values.length === 0) {
        return items
      }
      let sorted = orderBy(items, values.map(s => makeAccessor(s)), orders.map(o => o ? 'desc' : 'asc'))
      if (this.selected && Object.keys(this.selected).length) {
        return sorted
          .filter(({key}) => this.selected.hasOwnProperty(key))
          .concat(sorted.filter(({key}) => !this.selected.hasOwnProperty(key)))
      }
      return sorted
    }
  }
}
</script>
