<template>
  <table class="suite-summary">
    <tbody v-for="(suite, suiteId) in item.suites" small :key="suiteId">
      <tr><th colspan="2">{{suitesConfig.getSuiteLabel(suiteId)}}</th></tr>
      <tr v-for="(value, name) in suite" :key="name">
        <td style="width:1%">{{suitesConfig.getSuiteAttributeLabel(suiteId, {name})}}</td>
        <td>{{value}}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['header', 'item'],
  computed: {
    ...mapGetters(['suitesConfig'])
  }
}
</script>
<style scoped>
.suite-summary tr, th, td {
  padding: 0;
  margin: 0;
  height: 1rem;
}
.suite-summary td:first-child {
  padding-right: 0.5rem;
}
</style>
