<template>
  <v-chip small
    v-if="(value >= 0) && (value <= 1)"
    :color="color"
    text-color="white"
    >
    {{valueFormatted}}%
  </v-chip>
</template>
<script>
import get from 'object-get'
const colors = ['green', 'yellow', 'amber', 'orange', 'red']

export default {
  props: ['header', 'item'],
  computed: {
    value () { return get(this.item, this.header.value) },
    color () {
      let index = Math.min(colors.length-1, Math.ceil(colors.length * (1 - this.value)))
      return colors[index]
    },
    valueFormatted () {
      return Math.round(this.value * 100)
    }
  }
}
</script>
