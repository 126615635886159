<template>
  <v-checkbox v-model="checked"/>
</template>
<script>
import Vue from 'vue'
export default {
  props: ['header', 'item', 'items', 'selected'],
  computed: {
    checked: {
      get () { return this.selected[this.item.key] },
      set (v) {
        v ? Vue.set(this.selected, this.item.key, this.item) : Vue.delete(this.selected, this.item.key)
        // this.selected[this.item.key] = v
      }
    }
  }
}
</script>
