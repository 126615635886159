<template>
  <v-chip-group multiple row>
    <v-chip v-for="(suite, suiteId) in item.suites" small :key="suiteId">
      {{suitesConfig.getSuiteLabel(suiteId)}}
    </v-chip>
  </v-chip-group>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['header', 'item'],
  computed: {
    ...mapGetters(['suitesConfig'])
  }
}
</script>
